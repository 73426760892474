import React from "react";
import CTA from "../sections/homepage/CTA.js";
import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/about/heroabout.js";
import Content from "../sections/about/aboutcontent.js";
import Testimonial from "../sections/homepage/Testimonial.js";
import Brand from "../sections/homepage/Brands.js";
import Head from "../components/head"

import Alert from 'react-bootstrap/Alert';
import { Container, Row, Col } from "react-bootstrap";
import { Title, Section, Box, Text, Badge } from "../components/Core";

const Appointment = () => {
  return (
    <>
    <Head title="About Dermamina" image="/images/dermamina-home.jpg"  description="experts in non surgical nose job Rhinoplasty London" keywords="Nose jobs, Rhinoplasty, Liquid nose job, Liquid rhinoplasty, Surgical nose job, Nose filler, Rhinoplasty filler, Non surgical rhinoplasty, No surgery nose job, Pain free nose job"/>
      <PageWrapper headerDark>
       
        
            <Section>
            <Container>
                <Row className="mb-5 mt-5">
                    <Col>
                <Title>Appointment Confirmed!</Title>
        <Text>You will shortly receive a confirmation email with form(s).

We kindly ask that you complete the form(s) before attending, which should take no more than a few minutes to complete.

If you do not see anything in your inbox, please check your junk mail.

Thank you!</Text>

</Col>
</Row>

</Container>
</Section>
       
      </PageWrapper>
     
    </>
  );
};
export default Appointment;