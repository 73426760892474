import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text, Span } from "../../components/Core";
import trust from "../../assets/image/png/trust.svg"
import imgContent1 from "../../assets/image/jpeg/mina.jpg";
import imgContent2 from "../../assets/image/jpeg/dclinic.jpg";

const ContentImg = styled(Box)`
  box-shadow: ${({ theme }) => `0 42px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  overflow: hidden;
  max-height: 400px;
  max-width:50%;
  margin: 0 auto;
`;

const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;



const Arrow = styled.div`


width: 1.8em;
margin-left: 0.8em;
opacity: 0.6;

`;

const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;


@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
}


`;



const Iline = styled.div`

width: 6em;
height: 1px;
margin-right: 1em;
background-color: rgb(171, 142, 102);

@media screen and (max-width: 991px)
 {
  width: 3em;
}

`;

const Itext = styled.div`

margin-top: 0px;
margin-bottom: 0px;
color: rgb(171, 142, 102);
font-size: 14px;
font-weight: 400;


@media screen and (max-width: 991px)
 {
    font-size: 12px;
}

`;


const Aboutcontent = () => (
  <>
    <Section>
      <Container>

      <Row>
              <Col  data-aos="fade-up"
            data-aos-duration="500"
            data-aos-once="false"
            data-aos-delay="300">
                <Iwrap>


<Itext as="h2">Mina - Leading Cosmetics professional</Itext>
</Iwrap>

              </Col>
          </Row>


        <Row className="mt-1">
          <Col lg="4" sm="5" className="mb-4 " data-aos="fade-up"
                data-aos-duration="750"
                data-aos-once="false">
            <ContentImg>
              <img src={imgContent1} alt="" className="img-fluid" />
            </ContentImg>
          </Col>
          <Col lg="8" sm="7" className="mb-4" data-aos="fade-up"
                data-aos-duration="750"
                data-aos-once="false">

     


                  
              <Text>Mina is a professional independent prescriber, clinical pharmacist and aesthetic practitioner. Providing excellence in non-surgical treatments and positivity in skin ageing; Mina is our co-founder and heart of the company. <br /><br />
               Passionate to enhance the beauty and confidence of her patients by providing natural looking results, that elevate ones natural beauty, tailored to the patient’s individual suitability and needs.<br /><br />
Mina has true passion and expertise in the field of aesthetics and this is reflected in all her work. She has a vast portfolio of amazing results all performed without surgery. Before any treatment has begun, she will always provide an honest assessment to ensure you understand all the steps of the procedure.<br /><br />

Mina specialises in dermal fillers, anti-wrinkle injections, mesotherapy and much more. After every treatment, Mina strives to build a long lasting relationship with all her patients, to ensure they are truly happy with their results.<br /><br />

You will be guided with expert care, in a bespoke and comfortable environment.<br /><br />

 <i>“Mina's integrity is truly admirable, I could tell that my wellbeing was her priority as she was honest about what I could realistically achieve with the treatment, and she never tried to upsell me any other treatments. She also took the time to understand what I was trying to achieve and explained the procedure thoroughly. During the treatment she was very gentle and we had a lovely chat. As per the results, I'm very satisfied, I got exactly what I wanted“ - Gabriela</i></Text>

 <a href="https://uk.trustpilot.com/review/www.dermamina.com" target="_blank"> <img src={trust} width="150px" /></a>
         
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Aboutcontent;
